import {
    CoolerWidths,
    CpuBrands,
    CpuSockets,
    StorageInterfaces,
    MotherFormFactors,
    FormFactors,
    ProductTypes,
    RamStickTypes,
    CoolerTypes,
    StorageTypes,
    ConfigIds,
} from '../../helpers/types';

export default {
    product_types: [
        { id: ProductTypes.MOTHER, name: 'Mother' },
        { id: ProductTypes.CPU, name: 'CPU' },
        { id: ProductTypes.CPU_COOLER, name: 'CPU Cooler' },
        { id: ProductTypes.RAM, name: 'RAM' },
        { id: ProductTypes.GPU, name: 'GPU' },
        { id: ProductTypes.INTERNAL_STORAGE, name: 'Internal Storage' },
        { id: ProductTypes.EXTERNAL_STORAGE, name: 'External Storage' },
        { id: ProductTypes.CASE, name: 'Case' },
        { id: ProductTypes.CASE_FAN, name: 'Case fan' },
        { id: ProductTypes.POWER, name: 'Power' },
        { id: ProductTypes.MONITOR, name: 'Monitor' },
        { id: ProductTypes.MOUSE, name: 'Mouse' },
        { id: ProductTypes.KEYBOARD, name: 'Keyboard' },
        { id: ProductTypes.HEADSET, name: 'Headset' },
        { id: ProductTypes.MICROPHONE, name: 'Microphones' },
        { id: ProductTypes.CHAIR, name: 'Chair' },
        { id: ProductTypes.ACCESSORIES, name: 'Accessories' },
        { id: ProductTypes.SPEAKERS, name: 'Speakers' },
        { id: ProductTypes.THERMAL_PASTE, name: 'Thermal Paste' },
        { id: ProductTypes.FURNITURE, name: 'Furniture' },
        { id: ProductTypes.OPERATIVE_SYSTEM, name: 'Operative System' },
        { id: ProductTypes.OTHER, name: 'Other' },
        { id: ProductTypes.BUILD, name: 'PC Gamer' },
        { id: ProductTypes.NOTEBOOKS, name: 'Notebooks' },
    ],
    ram_stick_type: [
        { id: RamStickTypes.DDR_3, name: 'DDR3' },
        { id: RamStickTypes.DDR_4, name: 'DDR4' },
        { id: RamStickTypes.DDR_5, name: 'DDR5' },
    ],
    cpu_socket: [
        { id: CpuSockets.AM4, name: 'AMD AM4' },
        { id: CpuSockets.AM5, name: 'AMD AM5' },
        { id: CpuSockets.TRX4, name: 'AMD TRX4' },
        { id: CpuSockets.LGA1151, name: 'Intel 1151' },
        { id: CpuSockets.LGA1200, name: 'Intel 1200' },
        { id: CpuSockets.LGA1700, name: 'Intel 1700' },
        { id: CpuSockets.LGA2011, name: 'Intel 2011' },
    ],
    mother_form_factor: [
        { id: MotherFormFactors.EATX, name: 'EATX' },
        { id: MotherFormFactors.ATX, name: 'ATX' },
        { id: MotherFormFactors.MICRO_ATX, name: 'Micro-ATX' },
        { id: MotherFormFactors.MINI_ITX, name: 'Mini-ITX' },
    ],
    form_factor: [
        { id: FormFactors.FULL_TOWER, name: 'Full-Tower' },
        { id: FormFactors.MID_TOWER, name: 'Mid-Tower' },
        { id: FormFactors.MINI_TOWER, name: 'Mini-Tower' },
    ],
    storage_interface: [
        { id: StorageInterfaces.M2, name: 'M2' },
        { id: StorageInterfaces.SATA_3, name: 'SATA3' },
    ],
    radiator_width: [
        { id: '240', name: '240' },
        { id: '360', name: '360' },
    ],
    warranty: [
        { id: 'por 1 mes', name: '1 mes' },
        { id: 'por 3 meses', name: '3 meses' },
        { id: 'por 6 meses', name: '6 meses' },
        { id: 'por 1 año', name: '1 año' },
        { id: 'por 2 años', name: '2 años' },
        { id: 'por 3 años', name: '3 años' },
        { id: 'de por vida', name: 'De por vida' },
    ],
    cpu_brands: [
        { id: CpuBrands.AMD, name: 'AMD' },
        { id: CpuBrands.INTEL, name: 'Intel' },
    ],
    brands: [
        { id: 'Adata', name: 'Adata' },
        { id: 'AMD', name: 'AMD' },
        { id: 'Antec', name: 'Antec' },
        { id: 'Aoc', name: 'Aoc' },
        { id: 'APC', name: 'APC' },
        { id: 'Arctic cooling', name: 'Arctic cooling' },
        { id: 'Asrock', name: 'Asrock' },
        { id: 'Astro', name: 'Astro' },
        { id: 'Asus', name: 'Asus' },
        { id: 'Azza', name: 'Azza' },
        { id: 'Benq', name: 'Benq' },
        { id: 'Biostar', name: 'Biostar' },
        { id: 'Coolermaster', name: 'Coolermaster' },
        { id: 'Corsair', name: 'Corsair' },
        { id: 'Creative', name: 'Creative' },
        { id: 'Dell', name: 'Dell' },
        { id: 'Ducky', name: 'Ducky' },
        { id: 'Edifier', name: 'Edifier' },
        { id: 'Elgato', name: 'Elgato' },
        { id: 'EPIC', name: 'EPIC' },
        { id: 'EVGA', name: 'EVGA' },
        { id: 'Gainward', name: 'Gainward' },
        { id: 'Genius', name: 'Genius' },
        { id: 'Gigabyte', name: 'Gigabyte' },
        { id: 'Glorious', name: 'Glorious' },
        { id: 'Gskill', name: 'Gskill' },
        { id: 'Hikvision', name: 'Hikvision' },
        { id: 'HP', name: 'HP' },
        { id: 'Hyperx', name: 'Hyperx' },
        { id: 'Intel', name: 'Intel' },
        { id: 'JBL', name: 'JBL' },
        { id: 'Kingston', name: 'Kingston' },
        { id: 'Lexar', name: 'Lexar' },
        { id: 'LG', name: 'LG' },
        { id: 'Lian Li', name: 'Lian Li' },
        { id: 'Logitech', name: 'Logitech' },
        { id: 'Mad Catz', name: 'Mad Catz' },
        { id: 'Markvision', name: 'Markvision' },
        { id: 'Microsoft', name: 'Microsoft' },
        { id: 'MSI', name: 'MSI' },
        { id: 'Noctua', name: 'Noctua' },
        { id: 'NSX', name: 'NSX' },
        { id: 'Nvidia', name: 'Nvidia' },
        { id: 'NZXT', name: 'NZXT' },
        { id: 'Patriot', name: 'Patriot' },
        { id: 'Plantronics', name: 'Plantronics' },
        { id: 'PNY', name: 'PNY' },
        { id: 'PowerColor', name: 'PowerColor' },
        { id: 'Qbox', name: 'Qbox' },
        { id: 'Razer', name: 'Razer' },
        { id: 'Samsung', name: 'Samsung' },
        { id: 'SCUF', name: 'SCUF' },
        { id: 'Seagate', name: 'Seagate' },
        { id: 'Sennheiser', name: 'Sennheiser' },
        { id: 'Sentey', name: 'Sentey' },
        { id: 'SilverStone', name: 'SilverStone' },
        { id: 'Steam', name: 'Steam' },
        { id: 'Steelseries', name: 'Steelseries' },
        { id: 'Thermalright', name: 'Thermalright' },
        { id: 'ThermalTake', name: 'ThermalTake' },
        { id: 'Thrustmaster', name: 'Thrustmaster' },
        { id: 'Toshiba', name: 'Toshiba' },
        { id: 'TP-Link', name: 'TP-Link' },
        { id: 'Western Digital', name: 'Western Digital' },
        { id: 'XFX', name: 'XFX' },
        { id: 'Zotac', name: 'Zotac' },
    ],
    case_radiator_width: [
        { id: CoolerWidths.W_120, name: '120' },
        { id: CoolerWidths.W_140, name: '140' },
        { id: CoolerWidths.W_240, name: '240' },
        { id: CoolerWidths.W_280, name: '280' },
        { id: CoolerWidths.W_360, name: '360' },
    ],
    cooler_type: [
        { id: CoolerTypes.WATER_COOLER, name: 'Water Cooler' },
        { id: CoolerTypes.AIR_COOLER, name: 'Air Cooler' },
    ],
    internal_storage_type: [
        { id: StorageTypes.HDD, name: 'HDD' },
        { id: StorageTypes.SSD, name: 'SSD' },
    ],
    case_fan_width: [
        { id: CoolerWidths.W_120, name: '120' },
        { id: CoolerWidths.W_140, name: '140' },
    ],
    iva: [
        { id: 21, name: '21%' },
        { id: 10.5, name: '10.5%' },
    ],
    pricing: [
        { id: ConfigIds.INTEREST_RATE, name: ConfigIds.INTEREST_RATE },
        { id: ConfigIds.PRICING_CMP, name: ConfigIds.PRICING_CMP },
        { id: ConfigIds.PRICING_COM_BANCARIA, name: ConfigIds.PRICING_COM_BANCARIA },
        {
            id: ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA,
            name: ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA,
        },
        { id: ConfigIds.PRICING_G_ADM, name: ConfigIds.PRICING_G_ADM },
        { id: ConfigIds.PRICING_IIBB, name: ConfigIds.PRICING_IIBB },
        { id: ConfigIds.PRICING_MARKUP, name: ConfigIds.PRICING_MARKUP },
    ],
};
