import { ConfigsThatAreNotTaxes } from '../../helpers/types';

export const queryConfigs = {
    type: 'getList',
    resource: 'configs',
    payload: { pagination: { page: 1, perPage: 20 } },
};

export const getTaxConfig = (configsData) => configsData
    .filter((config) => !ConfigsThatAreNotTaxes.includes(config.id))
    .reduce((acc, config) => {
        acc[config.id] = config.value;
        return acc;
    }, {});
