import React from 'react';

import { TextField, Labeled, BooleanField } from 'react-admin';

const WithdrawalInfo = ({ record, ...rest }) => {
    const all = { record, ...rest };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Labeled label="Retiro por agencia">
                <BooleanField source="shippingData.isAgency" {...all} />
            </Labeled>
            {
                record?.shippingData?.isAgency ? (
                    <>
                        <Labeled label="Provincia">
                            <TextField source="shippingData.province" {...all} />
                        </Labeled>
                        <Labeled label="Localidad">
                            <TextField source="shippingData.locality" {...all} />
                        </Labeled>
                        <Labeled label="Dirección">
                            <TextField source="shippingData.address" {...all} />
                        </Labeled>
                    </>
                ) : (
                    <>
                        <Labeled label="Nombre">
                            <TextField source="shippingData.firstName" {...all} />
                        </Labeled>
                        <Labeled label="Apellido">
                            <TextField source="shippingData.lastName" {...all} />
                        </Labeled>
                        <Labeled label="DNI">
                            <TextField source="shippingData.dni" {...all} />
                        </Labeled>
                    </>
                )
            }
        </div>
    );
};

export default WithdrawalInfo;
