import React from 'react';
import { Labeled, NumberField, TextField } from 'react-admin';
import cartHelper from '../../helpers/cartHelper';

export default ({ record, dontShowSubtotal, ...rest }) => {
    const all = { record, ...rest };
    if (!record?.appliedCoupon) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!dontShowSubtotal && (
                <Labeled label="Subtotal price">
                    <NumberField
                        source="subTotalPrice"
                        min="0"
                        locales={cartHelper.formaterPesosLocales}
                        options={{
                            style: cartHelper.formaterPesosStyle,
                            currency: cartHelper.formaterPesosCurrency,
                        }}
                        {...all}
                    />
                </Labeled>
            )}
            <Labeled label="C&oacute;digo de descuento aplicado">
                <TextField source="appliedCoupon.code" {...all} />
            </Labeled>
            <Labeled label="Porcentaje de descuento aplicado">
                <NumberField
                    source="appliedCoupon.discount_percentage"
                    min="0"
                    max="100"
                    {...all}
                />
            </Labeled>
        </div>
    );
};
