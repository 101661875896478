import React from 'react';
import {
    ReferenceField,
    TextField,
    SelectField,
    Labeled,
} from 'react-admin';

import choices from '../choices';

const BillingFields = ({ record, ...rest }) => {
    const all = { record, ...rest };
    const invoiceType = record?.buyerData?.invoiceType;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Labeled label="Email">
                <ReferenceField reference="users" source="buyer" label="Comprador" linkType="show" {...all}>
                    <TextField source="email" />
                </ReferenceField>
            </Labeled>
            <Labeled label="Tipo de factura">
                <SelectField source="buyerData.invoiceType" choices={choices.invoicesTypes} {...all} />
            </Labeled>
            {
                invoiceType === 'invoiceA' ? (
                    <>
                        <Labeled label="CUIT">
                            <TextField source="buyerData.cuit" {...all} />
                        </Labeled>
                        <Labeled label="Razón social">
                            <TextField source="buyerData.businessName" {...all} />
                        </Labeled>
                        <Labeled label="Condición frente al IVA">
                            <SelectField source="buyerData.ivaCondition" choices={choices.ivaConditions} {...all} />
                        </Labeled>
                        <Labeled label="Provincia">
                            <TextField source="buyerData.province" {...all} />
                        </Labeled>
                        <Labeled label="Localidad">
                            <TextField source="buyerData.locality" {...all} />
                        </Labeled>
                        <Labeled label="Domicilio comercial">
                            <TextField source="buyerData.businessAddress" {...all} />
                        </Labeled>
                        <Labeled label="Piso/Depto">
                            <TextField source="buyerData.floor" {...all} />
                        </Labeled>
                    </>
                ) : (
                    <>
                        <Labeled label="Nombre">
                            <TextField source="buyerData.firstName" {...all} />
                        </Labeled>
                        <Labeled label="Apellido">
                            <TextField source="buyerData.lastName" {...all} />
                        </Labeled>
                        <Labeled label="DNI">
                            <TextField source="buyerData.dni" {...all} />
                        </Labeled>
                    </>
                )
            }
        </div>
    );
};

export default BillingFields;
