import * as React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    ShowButton,
    TextField,
    DateField,
    ReferenceField, SelectField, NumberField,
} from 'react-admin';

import OrderFilter from './OrderFilter';
import choices from './choices';
import cartHelper from '../../helpers/cartHelper';

export default (props) => (
    <List {...props} filters={<OrderFilter />} sort={{ field: 'statusDates.new', order: 'DESC' }} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="code" label="C&oacute;digo" />
            <TextField source="billId" label="N° Factura" />
            <ReferenceField reference="users" source="buyer" label="Comprador" sortable={false} linkType="show">
                <TextField source="email" reference="users" />
            </ReferenceField>
            <SelectField choices={choices.status} source="status" />
            <NumberField
                source="totalPrice"
                locales={cartHelper.formaterPesosLocales}
                options={{
                    style: cartHelper.formaterPesosStyle,
                    currency: cartHelper.formaterPesosCurrency,
                }}
            />
            <DateField source="statusDates.new" label="Fecha de Compra Realizada" showTime />
            <EditButton basePath="/orders" />
            <ShowButton basePath="/orders" />
        </Datagrid>
    </List>
);
