import React from 'react';
import {
    SelectInput,
    TextInput,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { required } from 'ra-core';

import choices, { orderStatus, orderCancellationReasons } from './choices';
import { cancellationReasonValidation, cancellationReasonDetailValidation } from './validations';

const StatusSelect = () => {
    const { initialValues } = useFormState();
    const { values: { status, cancellationReason } } = useFormState();

    return (
        <Box display="flex" flexDirection="column" width={256}>
            <SelectInput
                source="status"
                label="Estado"
                choices={choices.status}
                validate={[required()]}
                disabled={initialValues.status === orderStatus.cancelled}
            />
            {
                status === orderStatus.cancelled && (
                    <>
                        <SelectInput
                            source="cancellationReason"
                            label="Motivo de cancelación *"
                            choices={choices.cancellationReason}
                            disabled={initialValues.status === orderStatus.cancelled}
                            validate={(value) => cancellationReasonValidation(value, status)}
                        />
                        {
                            cancellationReason === orderCancellationReasons.other && (
                                <TextInput
                                    multiline
                                    label="Detalle del motivo *"
                                    source="cancellationReasonDetail"
                                    disabled={initialValues.status === orderStatus.cancelled}
                                    validate={(value) => cancellationReasonDetailValidation(
                                        value, cancellationReason,
                                    )}
                                />
                            )
                        }
                    </>
                )
            }
        </Box>
    );
};

export default StatusSelect;
