import React from 'react';
import { Divider } from '@material-ui/core';
import {
    TextField,
    Labeled,
    SelectField,
    NumberField,
} from 'react-admin';

import cartHelper from '../../../helpers/cartHelper';
import { SHIPPING_TYPES } from '../constants';
import WithdrawalInfo from './WithdrawalInfo';

const ShippingInfo = ({ record, ...rest }) => {
    const all = { record, ...rest };
    if (record?.shippingData?.shippingType === SHIPPING_TYPES.homeDelivery) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Labeled label="Nombre">
                    <TextField source="shippingData.firstName" {...all} />
                </Labeled>
                <Labeled label="Apellido">
                    <TextField source="shippingData.lastName" {...all} />
                </Labeled>
                <Labeled label="DNI (de quien recibe)">
                    <TextField source="shippingData.dni" {...all} />
                </Labeled>
                <Labeled label="Teléfono de contacto">
                    <TextField source="shippingData.phone" {...all} />
                </Labeled>
                <Labeled label="Provincia">
                    <SelectField source="shippingData.province" {...all} choices={cartHelper.getProvinces()} />
                </Labeled>
                <Labeled label="Localidad">
                    <TextField source="shippingData.locality" {...all} />
                </Labeled>
                <Labeled label="Dirección">
                    <TextField source="shippingData.address" {...all} />
                </Labeled>
                <Labeled label="Código postal">
                    <TextField source="shippingData.codigoPostal" {...all} />
                </Labeled>
                <Labeled label="Piso">
                    <TextField source="shippingData.floor" {...all} />
                </Labeled>
            </div>
        );
    }

    if (record?.shippingData?.shippingType === SHIPPING_TYPES.pickupAtWarehouse) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Labeled label="Nombre">
                    <TextField source="shippingData.firstName" {...all} />
                </Labeled>
                <Labeled label="Apellido">
                    <TextField source="shippingData.lastName" {...all} />
                </Labeled>
                <Labeled label="DNI">
                    <TextField source="shippingData.dni" {...all} />
                </Labeled>
            </div>
        );
    }

    if (record?.shippingData?.shippingType === SHIPPING_TYPES.pickupAtNearestAgency) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Labeled label="Provincia">
                    <TextField source="shippingData.province" {...all} />
                </Labeled>
                <Labeled label="Localidad">
                    <TextField source="shippingData.locality" {...all} />
                </Labeled>
                <Labeled label="Dirección">
                    <TextField source="shippingData.address" {...all} />
                </Labeled>
                <Labeled label="Teléfono de contacto">
                    <TextField source="shippingData.phone" {...all} />
                </Labeled>
                {
                    record?.shippingData?.isThirdPartyAuthorized && (
                        <>
                            <Divider />
                            <Labeled label="Nombre">
                                <TextField source="shippingData.firstName" {...all} />
                            </Labeled>
                            <Labeled label="Apellido">
                                <TextField source="shippingData.lastName" {...all} />
                            </Labeled>
                            <Labeled label="DNI">
                                <TextField source="shippingData.dni" {...all} />
                            </Labeled>
                        </>
                    )
                }
            </div>
        );
    }

    // IMPORTANT: GD-732 - This is a solution for old orders
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Labeled label="Nombre">
                <TextField source="shippingData.firstName" {...all} />
            </Labeled>
            <Labeled label="Apellido">
                <TextField source="shippingData.lastName" {...all} />
            </Labeled>
            <Labeled label="DNI">
                <TextField source="shippingData.dni" {...all} />
            </Labeled>
            <Labeled label="Teléfono de contacto">
                <TextField source="shippingData.phone" {...all} />
            </Labeled>
            <Labeled label="Provincia">
                <SelectField source="shippingData.province" choices={cartHelper.getProvinces()} {...all} />
            </Labeled>
            <Labeled label="Localidad">
                <TextField source="shippingData.locality" {...all} />
            </Labeled>
            <Labeled label="Dirección">
                <TextField source="shippingData.address" {...all} />
            </Labeled>
            <Labeled label="Código postal">
                <TextField source="shippingData.codigoPostal" {...all} />
            </Labeled>
            <Labeled label="Número">
                <NumberField source="shippingData.number" min="0" {...all} />
            </Labeled>
            <Labeled label="Piso">
                <TextField source="shippingData.floor" {...all} />
            </Labeled>
            <WithdrawalInfo record={record} {...rest} />
        </div>
    );
};

export default ShippingInfo;
