import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    NumberField,
    DateField,
    ArrayField,
    Datagrid,
    FunctionField,
    ShowButton,
    SelectField,
    TopToolbar,
    EditButton,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import OrderTitle from '../OrderTitle';
import cartHelper from '../../../helpers/cartHelper';
import RequestShippingButton from '../RequestShippingButton';
import PrintShippingLabels from '../DownloadShippingLabelsButton';
import choices from '../choices';
import DiscountCodeFields from '../DiscountCodeFields';
import BillingFields from './BillingFields';
import ShippingInfo from './ShippingInfo';
import { SHIPPING_TYPES_LABELS } from '../constants';

const OrderActions = ({ basePath, data }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        { data?.status === 'payed' && <RequestShippingButton record={data} /> }
        { data?.status === 'shippingRequested' && <PrintShippingLabels record={data} /> }
    </TopToolbar>
);

const OrderShow = (props) => (
    <Show title={<OrderTitle />} actions={<OrderActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <BillingFields />
            </Tab>
            <Tab label="Datos de la compra">
                <SelectField source="paymentData.method" choices={choices.paymentMethods} label="Método de pago" />
                <SelectField choices={choices.status} source="status" />
                <TextField source="billId" label="Número de factura" />
                <TextField source="trackingNumber" label="Número de guía" />
                <NumberField
                    label="Sub total"
                    source="subTotalPrice"
                    locales={cartHelper.formaterPesosLocales}
                    options={{
                        style: cartHelper.formaterPesosStyle,
                        currency: cartHelper.formaterPesosCurrency,
                    }}
                />
                <DiscountCodeFields />
                <NumberField
                    label="Total"
                    source="totalPrice"
                    locales={cartHelper.formaterPesosLocales}
                    options={{
                        style: cartHelper.formaterPesosStyle,
                        currency: cartHelper.formaterPesosCurrency,
                    }}
                />
            </Tab>
            <Tab label="Mercado pago">
                <JsonField source="paymentData.mpRequest" label="Request a MP al pagar" />
                <JsonField source="paymentData.mpResponse" label="Respuesta de MP al pagar" />
            </Tab>
            <Tab label="Shipping Data">
                <FunctionField label="Tipo de envío" render={(rec) => SHIPPING_TYPES_LABELS[rec?.shippingData?.shippingType] || '-'} />
                <ShippingInfo />
            </Tab>
            <Tab label="Via Cargo">
                <JsonField source="vcRequest" label="Request a VC al solicitar env&iacute;o" />
                <JsonField source="vcResponse" label="Respuesta de VC al solicitar env&iacute;o" />
            </Tab>
            <Tab label="Fechas">
                <DateField source="statusDates.new" label="Fecha de Compra Realizada" showTime />
                <DateField source="statusDates.payed" label="Fecha de Pago Aprobado" showTime />
                <DateField source="statusDates.shippingRequested" label="Fecha de Solicitud de Envío" showTime />
                <DateField source="statusDates.delivering" label="Fecha de Despacho" showTime />
                <DateField source="statusDates.completed" label="Fecha de Entrega" showTime />
            </Tab>
            <Tab label="Productos">
                <ArrayField source="products">
                    <Datagrid>
                        <TextField label="Build Id" source="item.buildId" sortable={false} />
                        <TextField label="Nombre" source="item.productShadow.name" sortable={false} />
                        <TextField label="Código" source="item.productShadow.code" sortable={false} />
                        <NumberField
                            label="Precio especial"
                            source="item.price.special"
                            locales={cartHelper.formaterPesosLocales}
                            options={{
                                style: cartHelper.formaterPesosStyle,
                                currency: cartHelper.formaterPesosCurrency,
                            }}
                            sortable={false}
                        />
                        <NumberField
                            label="Precio lista"
                            source="item.price.list"
                            locales={cartHelper.formaterPesosLocales}
                            options={{
                                style: cartHelper.formaterPesosStyle,
                                currency: cartHelper.formaterPesosCurrency,
                            }}
                            sortable={false}
                        />
                        <NumberField label="Cantidad" source="item.quantity" sortable={false} />
                        <TextField source="serialNumber" label="Número de Serie" sortable={false} />
                        <TextField source="productBillId" label="FC Proveedor" sortable={false} />
                        <FunctionField render={
                            (record) => <ShowButton record={{ id: record.item.product }} basePath="/products" />
                        }
                        />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default OrderShow;
