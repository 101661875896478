import * as React from 'react';
import { useFormState } from 'react-final-form';
import {
    SelectInput,
    TextInput,
    NumberInput,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import { required } from 'ra-core';
import { Tooltip } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import choices from './choices';
import GameCompatibilityInput from './Builds/GameCompatibilityInput';

const ramWattsTooltipMessage = () => 'La cantidad de watts debe ser el producto entre cantidad y los watts que consume una RAM unitaria.';

export default () => {
    const { values } = useFormState();

    const handleMotherRamStickTypeChoices = () => {
        if (values.specs?.cpu_socket && values.specs?.cpu_socket === 'AM5') {
            return choices.ram_stick_type.filter((ramStick) => ramStick.name === 'DDR5');
        }
        return choices.ram_stick_type;
    };

    const specsForTypes = {
        case_fan: (
            <>
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
                <SelectInput
                    label="Case width (mm)"
                    source="specs.width"
                    choices={choices.case_fan_width}
                    validate={[required()]}
                />
            </>),
        case: (
            <>
                <SelectInput
                    label="Mother form factor"
                    addLabel
                    source="specs.mother_form_factor"
                    choices={choices.mother_form_factor}
                    validate={[required()]}
                />
                <SelectInput
                    label="Form factor"
                    addLabel
                    source="specs.form_factor"
                    choices={choices.form_factor}
                    validate={[required()]}
                />
                <NumberInput
                    label="Width (mm)"
                    source="specs.width"
                    validate={[required()]}
                />
                <NumberInput
                    label="Fan slots 120mm"
                    source="specs.fan_slots_120"
                    validate={[required()]}
                />
                <NumberInput
                    label="Fan slots 140mm"
                    source="specs.fan_slots_140"
                    validate={[required()]}
                />
                <NumberInput
                    label="Fan slots 240mm"
                    source="specs.fan_slots_240"
                    validate={[required()]}
                />
                <NumberInput
                    label="Fan slots 280mm"
                    source="specs.fan_slots_280"
                    validate={[required()]}
                />
                <NumberInput
                    label="Fan slots 360mm"
                    source="specs.fan_slots_360"
                    validate={[required()]}
                />
                <TextInput
                    label="Radiator Support"
                    source="specs.radiator_support"
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
            </>
        ),
        power: (
            <>
                <NumberInput
                    label="Watts"
                    source="specs.watts_supported"
                    validate={[required()]}
                />
            </>
        ),
        gpu: (
            <>
                <NumberInput
                    label="Width (mm)"
                    source="specs.width"
                    validate={[required()]}
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
            </>
        ),
        internal_storage: (
            <>
                <SelectInput
                    label="Interface"
                    addLabel
                    source="specs.interface"
                    choices={choices.storage_interface}
                    validate={[required()]}
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
                <SelectInput
                    label="Storage Type"
                    addLabel
                    source="specs.storage_type"
                    choices={choices.internal_storage_type}
                    validate={[required()]}
                />
            </>
        ),
        external_storage: (
            <>
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
            </>
        ),
        cpu_cooler: (
            <>
                <AutocompleteArrayInput
                    label="CPU Socket"
                    addLabel
                    source="specs.sockets"
                    choices={choices.cpu_socket}
                    validate={[required()]}
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
                <BooleanInput
                    label="Case radiator"
                    source="specs.case_radiator"
                />
                <SelectInput
                    label="Case radiator width (mm)"
                    source="specs.case_radiator_width"
                    choices={choices.case_radiator_width}
                />
                <SelectInput
                    label="Cooler Type"
                    source="specs.cooler_type"
                    choices={choices.cooler_type}
                />
            </>
        ),
        cpu: (
            <>
                <SelectInput
                    label="Socket"
                    addLabel
                    source="specs.socket"
                    choices={choices.cpu_socket}
                    validate={[required()]}
                />
                <BooleanInput
                    label="Has cooler"
                    source="specs.has_cooler"
                    defaultValue
                />
                <BooleanInput
                    label="Integrated GPU"
                    source="specs.igpu"
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
            </>
        ),
        ram: (
            <>
                <TextInput
                    label="RAM Capacity"
                    source="specs.capacity"
                    validate={[required()]}
                />
                <SelectInput
                    label="RAM Stick Type"
                    source="specs.stick_type"
                    choices={choices.ram_stick_type}
                    validate={[required()]}
                />
                <NumberInput
                    label="Stick Quantity"
                    source="specs.quantity"
                    initialValue="1"
                    validate={[required()]}
                />
                <TextInput
                    label="CL"
                    source="specs.cl"
                    validate={[required()]}
                />
                <TextInput
                    label="RAM Clock Frequency"
                    source="specs.clock_frequency"
                    validate={[required()]}
                />
                <Tooltip title={ramWattsTooltipMessage()}>
                    <NumberInput
                        label="Watts consumed"
                        source="specs.watts"
                        validate={[required()]}
                    />
                </Tooltip>
            </>
        ),
        mother: (
            <>
                <SelectInput
                    label="CPU socket"
                    addLabel
                    source="specs.cpu_socket"
                    choices={choices.cpu_socket}
                    validate={[required()]}
                />
                <SelectInput
                    label="RAM Stick Type"
                    addLabel
                    source="specs.ram_stick_type"
                    choices={handleMotherRamStickTypeChoices()}
                    validate={[required()]}
                />
                <SelectInput
                    label="Form factor"
                    addLabel
                    source="specs.form_factor"
                    choices={choices.mother_form_factor}
                    validate={[required()]}
                />
                <NumberInput
                    label="RAM Slots"
                    source="specs.ram_slots"
                    validate={[required()]}
                />
                <NumberInput
                    label="PCIe 16x slots"
                    source="specs.pci_express_16x_slots"
                    validate={[required()]}
                />
                <NumberInput
                    label="SATA3 slots"
                    source="specs.sata3_slots"
                    validate={[required()]}
                />
                <NumberInput
                    label="M2 slots"
                    source="specs.m2_slots"
                    validate={[required()]}
                />
                <BooleanInput
                    label="Video Output"
                    source="specs.video_output"
                    validate={[required()]}
                    defaultValue={false}
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
            </>
        ),
        monitor: (
            <>
                <TextInput
                    label="Connections"
                    source="specs.connections"
                />
                <TextInput
                    label="Inches"
                    source="specs.inches"
                />
                <TextInput
                    label="Refresh Rate"
                    source="specs.refresh_rate"
                />
                <TextInput
                    label="Resolution"
                    source="specs.resolution"
                />
            </>
        ),
        build: (
            <>
                <BooleanInput
                    label="Customizable"
                    source="specs.customizable"
                    defaultValue
                />
                <SelectInput
                    label="CPU Brand"
                    source="specs.cpu_brand"
                    choices={choices.cpu_brands}
                    validate={[required()]}
                />
                <RichTextInput
                    source="specs.operativeSystem"
                    label="Sistema Operativo"
                />
                <ReferenceArrayInput label="Monitor" reference="products" allowDuplicates source="specs.monitor.products" filter={{ type: 'monitor' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.monitor.description"
                    label="Descripción monitor. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Mouse" reference="products" allowDuplicates source="specs.mouse.products" filter={{ type: 'mouse' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.mouse.description"
                    label="Descripción mouse. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <RichTextInput
                    source="specs.connectivity"
                    label="Conectividad"
                />
                <RichTextInput
                    source="specs.ports"
                    label="Puertos"
                />
                <ReferenceArrayInput label="Teclado" reference="products" allowDuplicates source="specs.keyboard.products" filter={{ type: 'keyboard' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.keyboard.description"
                    label="Descripción teclado. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Mother" allowDuplicates reference="products" source="specs.mother.products" filter={{ type: 'mother' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.mother.description"
                    label="Descripción mother. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="CPU" allowDuplicates reference="products" source="specs.cpu.products" filter={{ type: 'cpu' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.cpu.description"
                    label="Descripción CPU. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="RAM" allowDuplicates reference="products" source="specs.ram.products" filter={{ type: 'ram' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.ram.description"
                    label="Descripción RAM. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="GPU" allowDuplicates reference="products" source="specs.gpu.products" filter={{ type: 'gpu' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.gpu.description"
                    label="Descripción GPU. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Storage" allowDuplicates reference="products" source="specs.internal_storage.products" filter={{ type: 'internal_storage' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.internal_storage.description"
                    label="Descripción Storage. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Case" allowDuplicates reference="products" source="specs.case.products" filter={{ type: 'case' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.case.description"
                    label="Descripción case. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Power" allowDuplicates reference="products" source="specs.power.products" filter={{ type: 'power' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.power.description"
                    label="Descripción power. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"

                />
                <GameCompatibilityInput />
            </>
        ),
    };
    return specsForTypes[values.type] ?? <br />;
};
