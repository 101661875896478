import { useState, useEffect } from 'react';
import {
    useNotify,
    useQueryWithStore,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { getTaxConfig, queryConfigs } from './utils';

const useStyles = makeStyles({
    conditionalCustomPricingField: {
        padding: '0px 0px',
        '&:last-child': {
            paddingBottom: '0px',
        },
    },
});

const useProduct = () => {
    const classes = useStyles();
    const notify = useNotify();
    const [dataTaxesConfig, setDataTaxesConfig] = useState({});
    const onFailure = (error) => {
        if (error.body.error.includes('E11000')) {
            notify('No se puede crear producto: Código duplicado');
        } else {
            notify(`No se puede crear producto: ${error.body.error}`);
        }
    };

    const { data: configsData } = useQueryWithStore(queryConfigs);

    useEffect(() => {
        if (configsData) {
            const formattedData = getTaxConfig(configsData);
            setDataTaxesConfig(formattedData);
        }
    }, [configsData]);

    return {
        classes,
        dataTaxesConfig,
        onFailure,
    };
};

export default useProduct;
