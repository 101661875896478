import * as React from 'react';
import {
    Button,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const productParameterTypeDictionary = {
    serialNumber: 'Serial Number',
    productBillId: 'Product Bill Id',
};

export default ({ record, orderId, productParameterToSetType }) => {
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [productParameter, setProductParameter] = React.useState('');
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (record) setProductParameter(record[productParameterToSetType]);
    }, [record]);

    const handleSerialNumberChange = () => {
        if (productParameter === '') notify(`${productParameterTypeDictionary[productParameterToSetType]} field empty.`);
        else {
            setLoading(true);
            dataProvider.setProductParameter('order', {
                id: orderId,
                productId: record.item.product,
                productParameterToSet: productParameter,
                productParameterToSetType,
            })
                .then(({ data }) => {
                    setLoading(false);
                    handleClose();
                    notify(`${productParameterTypeDictionary[productParameterToSetType]} setted successfully!`);
                    setProductParameter('');
                    refresh();
                })
                .catch((error) => {
                    setLoading(false);
                    handleClose();
                    console.error(error);
                    notify(`Error setting ${productParameterTypeDictionary[productParameterToSetType]}.`, 'warning');
                });
        }
    };

    const dialog = (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" {...record}>
            <DialogTitle id="form-dialog-title">{`Set ${productParameterTypeDictionary[productParameterToSetType]}`}</DialogTitle>
            <DialogContent>
                {record
                    && (
                        <>
                            <p>{record.item.productShadow.name}</p>
                            <TextField
                                autoFocus
                                required
                                label={`${productParameterTypeDictionary[productParameterToSetType]}`}
                                type="text"
                                value={productParameter}
                                onChange={(e) => {
                                    setProductParameter(e.target.value);
                                }}
                            />
                        </>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label="Cancel" />
                <Button disabled={loading} onClick={handleSerialNumberChange} label={`Set ${productParameterTypeDictionary[productParameterToSetType]}`} />
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Button label={`${productParameterTypeDictionary[productParameterToSetType]}`} onClick={handleClickOpen} disabled={open} alignIcon="left">
                <Edit />
            </Button>
            {dialog}
        </>
    );
};
