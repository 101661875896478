import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
    Labeled,
    NumberField,
    SelectField,
    TextField,
    BooleanField,
    RichTextField,
    ReferenceArrayField,
    Datagrid,
    ShowButton,
    ReferenceField,
    ArrayField,
} from 'react-admin';
import choices from './choices';

const CustomSocketListField = ({ data, choices: socketChoices }) => Object.values(data).map(
    (idSocket) => {
        const socket = socketChoices.find((item) => item.id === idSocket);
        return <Typography variant="body2">{socket.name}</Typography>;
    },
);

export default ({ record, ...rest }) => {
    const specsForTypes = {
        case_fan: (
            <>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
                <Labeled label="width">
                    <NumberField
                        source="specs.width"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        case: (
            <>
                <Labeled label="Mother Form factor">
                    <SelectField
                        source="specs.mother_form_factor"
                        choices={choices.mother_form_factor}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Form factor">
                    <SelectField
                        source="specs.form_factor"
                        choices={choices.form_factor}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Width (mm)">
                    <NumberField
                        source="specs.width"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Fan slots 120mm">
                    <NumberField
                        source="specs.fan_slots_120"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Fan slots 140mm">
                    <NumberField
                        source="specs.fan_slots_140"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Fan slots 240mm">
                    <NumberField
                        source="specs.fan_slots_240"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Fan slots 280mm">
                    <NumberField
                        source="specs.fan_slots_280"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Fan slots 360mm">
                    <NumberField
                        source="specs.fan_slots_360"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Radiator Support">
                    <TextField
                        source="specs.radiator_support"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        cpu_cooler: (
            <>
                <Labeled label="CPU Socket">
                    <ArrayField
                        choices={choices.cpu_socket}
                        record={record}
                        source="specs.sockets"
                    >
                        <CustomSocketListField />
                    </ArrayField>
                </Labeled>
                <Labeled label="Case radiator">
                    <BooleanField
                        source="specs.case_radiator"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Case radiator width (mm)">
                    <NumberField
                        source="specs.case_radiator_width"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Cooler Type">
                    <SelectField
                        source="specs.cooler_type"
                        choices={choices.cooler_type}
                        record={record}
                    />
                </Labeled>
            </>
        ),
        power: (
            <>
                <Labeled label="Watts supported">
                    <NumberField
                        source="specs.watts_supported"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        gpu: (
            <>
                <Labeled label="Width (mm)">
                    <NumberField
                        source="specs.width"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        internal_storage: (
            <>
                <Labeled label="Interface">
                    <SelectField
                        source="specs.interface"
                        choices={choices.storage_interface}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Storage Type">
                    <SelectField
                        source="specs.storage_type"
                        choices={choices.internal_storage_type}
                        record={record}
                    />
                </Labeled>
            </>
        ),
        external_storage: (
            <>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        cpu: (
            <>
                <Labeled label="Socket">
                    <SelectField
                        source="specs.socket"
                        choices={choices.cpu_socket}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Has cooler">
                    <BooleanField
                        source="specs.has_cooler"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Integrated GPU">
                    <BooleanField
                        source="specs.igpu"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        ram: (
            <>
                <Labeled label="RAM Capacity">
                    <TextField
                        source="specs.capacity"
                        record={record}
                    />
                </Labeled>
                <Labeled label="RAM Stick Type">
                    <SelectField
                        source="specs.stick_type"
                        choices={choices.ram_stick_type}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Stick Quantity">
                    <NumberField
                        source="specs.quantity"
                        record={record}
                    />
                </Labeled>
                <Labeled label="CL">
                    <TextField
                        source="specs.cl"
                        record={record}
                    />
                </Labeled>
                <Labeled label="RAM Clock Frequency">
                    <TextField
                        source="specs.clock_frequency"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        mother: (
            <>
                <Labeled label="CPU socket">
                    <SelectField
                        source="specs.cpu_socket"
                        choices={choices.cpu_socket}
                        record={record}
                    />
                </Labeled>
                <Labeled label="RAM Stick Type">
                    <SelectField
                        source="specs.ram_stick_type"
                        choices={choices.ram_stick_type}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Form factor">
                    <SelectField
                        source="specs.form_factor"
                        choices={choices.mother_form_factor}
                        record={record}
                    />
                </Labeled>
                <Labeled label="RAM Slots">
                    <NumberField
                        source="specs.ram_slots"
                        record={record}
                    />
                </Labeled>
                <Labeled label="PCIe 16x slots">
                    <NumberField
                        source="specs.pci_express_16x_slots"
                        record={record}
                    />
                </Labeled>
                <Labeled label="SATA3 slots">
                    <NumberField
                        source="specs.sata3_slots"
                        record={record}
                    />
                </Labeled>
                <Labeled label="M2 slots">
                    <NumberField
                        source="specs.m2_slots"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Video output">
                    <BooleanField
                        source="specs.video_output"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Watts consumed">
                    <NumberField
                        source="specs.watts"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        monitor: (
            <>
                <Labeled label="Connections">
                    <TextField
                        source="specs.connections"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Inches">
                    <TextField
                        source="specs.inches"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Refresh Rate">
                    <TextField
                        source="specs.refresh_rate"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Resolution">
                    <TextField
                        source="specs.resolution"
                        record={record}
                    />
                </Labeled>
            </>
        ),
        build: (
            <Box display="flex" flexDirection="column">
                <Labeled label="Customizable">
                    <BooleanField
                        source="specs.customizable"
                        record={record}
                    />
                </Labeled>
                <Labeled label="CPU Brand">
                    <SelectField
                        source="specs.cpu_brand"
                        choices={choices.cpu_brands}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Sistema Operativo">
                    <RichTextField
                        source="specs.operativeSystem"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Monitor">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.monitor.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción monitor">
                    <RichTextField
                        source="specs.monitor.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Mouse">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.mouse.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción mouse">
                    <RichTextField
                        source="specs.mouse.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Teclado">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.keyboard.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción teclado">
                    <RichTextField
                        source="specs.keyboard.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Conectividad">
                    <RichTextField
                        source="specs.connectivity"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Puertos">
                    <RichTextField
                        source="specs.ports"
                        record={record}
                    />
                </Labeled>

                <Labeled label="Mother">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.mother.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción mother">
                    <RichTextField
                        source="specs.mother.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="CPU">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.cpu.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción CPU">
                    <RichTextField
                        source="specs.cpu.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="RAM">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.ram.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción RAM">
                    <RichTextField
                        source="specs.ram.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="GPU">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.gpu.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción GPU">
                    <RichTextField
                        source="specs.gpu.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Storage">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.internal_storage.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción Storage">
                    <RichTextField
                        source="specs.internal_storage.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Case">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.case.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción case">
                    <RichTextField
                        source="specs.case.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Power">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.power.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción power">
                    <RichTextField
                        source="specs.power.description"
                        record={record}
                    />
                </Labeled>

                <ArrayField
                    source="specs.games_compatibility"
                    label="Juegos Compatibles"
                    record={record}
                    {...rest}
                >
                    <Datagrid style={{ tableLayout: 'fixed' }}>
                        <ReferenceField
                            reference="games"
                            source="game"
                            label="Game"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <BooleanField label="Compatible" source="isCompatible" />
                    </Datagrid>
                </ArrayField>
            </Box>

        ),
    };
    return specsForTypes[record.type] ?? <></>;
};
